import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import "./Referrals.scss";
import Paginate from "../../Ui/Paginate/Paginate";
import Loader from "../../../Loader/Loader";
import InputCustom from "../../Ui/input/InputCustom";
import { toast } from "../../Ui/Toast/Toast";
import { getReferralList } from "../../../redux/_actions/referralAction";
import debounce from 'lodash.debounce';

export default function Referrals() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Category of Referee",
      dataIndex: "category",
      key: "catgory",
    },
    {
      title: "No. of Referral",
      dataIndex: "referral",
      key: "referral",
    },
    {
      title: " Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');
  useEffect(() => {
    console.log('chk search:::::', search)
    fetchReferralList();
  }, [pageCount, search]);
  const fetchReferralList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    setTimeout(() => {
      dispatch(getReferralList({ data })).then(response => {
        const res = response.data;
        setUserData(res);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages)
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return (
      {
        key: index + 1,
        SNo: (<label className="ml-15">{(((metaData?.page - 1) * limit) + (index + 1))}</label>), 
        name: item?.referrals_user_data?.user_name,
        email: item?.referrals_user_data?.email,
        category: item?.type,
        referral: (<label className="ml-50">{(item?.referrals_user_data?.mas_fran_referred + item?.referrals_user_data?.pre_fran_referred + item?.referrals_user_data?.gp_referred + item?.referrals_user_data?.fran_referred)}</label>),
        action: (<Link to={"/referralDetails"} state={{ user_id: item?.referrals_user_data?.user_id }} className="buttonView"><span className="fontFamilyText">View<EyeOutlined /></span></Link>)
      }
    )
  });
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  return (
    <div>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Referral</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="commonPadding userDatils Referrals">
        <div className="annoucements_block_multiData">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
        {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      </div>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}

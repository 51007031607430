import React from "react";
import { Select } from "antd";
import "./CustomSelect.scss";
function CustomSelect(props) {
  const {
    images,
    outPagesSelect,
    innerPagesSelect,
    innerTxtField,
    label,
    labelcustom,
    fillBg,
    CustomSelectStyle,
    paddingLeft,
  } = props;
  const { Option } = Select;

  return (
    <div className="SelectOuter">
      {label ? <label className={`label ${labelcustom}`}>{label}</label> : null}
      <Select
        {...props}
        className={`customSelect ${outPagesSelect ? "outPagesSelect" : ""} ${innerPagesSelect ? "innerPagesSelect" : ""
          } ${fillBg && "fillBg"} ${CustomSelectStyle} ${paddingLeft && "paddingLeft"
          }`}
        value={props.defaultData}
      >
        {props.data && props.data.map((data) => (
          <Option value={data.value}>
            {images ? (
              <img style={{ height: 33, width: 33 }} src={data.img} />
            ) : null}
            {data.label}
          </Option>
        ))}
      </Select>
      {innerTxtField ? (
        <span className="innerTxtField">{innerTxtField}</span>
      ) : null}
    </div>
  );
}

export default CustomSelect;

import { Breadcrumb, DatePicker, Table } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { useState } from "react";
import Paginate from "../../Ui/Paginate/Paginate";
import { getCardUserList } from "../../../redux/_actions/cardAction";
import { Link } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";
import { DownloadOutlined } from "@ant-design/icons";
import "./CryptoPortfolio.scss";

let paginationData = "";
function CryptoPortfolio() {
  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Transaction Hash / Order ID",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Profit %",
      dataIndex: "profit",
      key: "profit",
    },
    {
      title: "Profit amount",
      dataIndex: "profitAmount",
      key: "profitAmount",
    },
  ];

  //   const limit = 2;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    console.log("chk search:::::", search);
    fetchCardUserList();
  }, [pageCount, search]);
  /************************************** fetchCardUserList ****************************************************/
  const fetchCardUserList = () => {
    //     setLoading(true);
    const data = {
      //   limit: limit,
      page: pageCount,
      //   search: search
    };
    // setTimeout(() => {
    //   dispatch(getCardUserList({ data })).then(response => {
    //     const res = response.data
    //     if (res.length > 0) {
    //       res.map((item, index) => item['key'] = index + 1);
    //       setUserData(res)
    //     } else {
    //       setUserData()
    //     }
    //     paginationData = response?.meta?.pages || 0
    //     setLoading(false)

    //   }).catch(err => {
    //     toast.error(err)
    //     setLoading(false)
    //   })
    // }, 100);
  };
  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return {
      key: item.key,
      user_name: <div className="tableIconStyle"></div>,
      email: <div className="tableIconStyle"></div>,
      order: <div style={{ marginLeft: 15 }} className="tableIconStyle "></div>,
      amount: <div className="tableIconStyle"></div>,
      fee: <div style={{ textTransform: "capitalize" }}></div>,
      profit: <div style={{ textTransform: "capitalize" }}></div>,
      profitAmount: <div style={{ textTransform: "capitalize" }}></div>,
    };
  });
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  return (
    <>
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>Crypto Portfolio Revenue Details </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding userDatils">
        <div className="annoucements_block_multiData">
          <div
            className="cryptoPortfolio_link"
            style={{ justifyContent: "end", display: "flex" }}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
            />
            <Link to={""}>
              Download CSV{" "}
              <span>
                <DownloadOutlined />
              </span>
            </Link>
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default CryptoPortfolio;

import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCallGet, apiCallPost } from "../../Services/ApiClient";
import { CHANGE_PASSWORD_API, GET_USER_DATA, GET_USER_DETAILS_API, GET_USER_LIST } from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getUserList ****************************************************/
export const getUserList = ({ data }) => {
          return dispatch => {
                    return new Promise((resolve, reject) => {
                              apiCallPost(GET_USER_LIST, data).then(response => {
                                        const res = response.data;
                                        console.log('res getUserList::::::', response);
                                        resolve(res);
                              }).catch(error => {
                                if (error?.message == 'Unable to decode data.') {
                                  commonError()
                                } else {
                                        const errorMessage = error.message || error;
                                        console.log('getUserList er::::::', error);
                                        reject(errorMessage);
                                }
                              });
                    });
          };
};
/************************************** getUserData ****************************************************/
export const getUserData = ({ data }) => {
          return dispatch => {
                    return new Promise((resolve, reject) => {
                              apiCallPost(GET_USER_DATA, data).then(response => {
                                        const res = response.data;
                                        //console.log('res getUserData::::::', response);
                                        resolve(res);
                              }).catch(error => {
                                if (error?.message == 'Unable to decode data.') {
                                  commonError()
                                } else {
                                        const errorMessage = error.message || error;
                                        //console.log('getUserData er::::::', error);
                                        reject(errorMessage);
                                }
                              });
                    });
          };
};
/************************************** getUserData ****************************************************/
export const getUserTxnDetails = ({ data }) => {
          return dispatch => {
                    return new Promise((resolve, reject) => {
                              apiCallPost(GET_USER_DETAILS_API, data).then(response => {
                                        const res = response.data;
                                        //console.log('res getUserTxnDetails::::::', response);
                                        resolve(res);
                              }).catch(error => {
                                if (error?.message == 'Unable to decode data.') {
                                  commonError()
                                } else {
                                        const errorMessage = error.message || error;
                                        //console.log('getUserTxnDetails er::::::', error);
                                        reject(errorMessage);
                                }
                              });
                    });
          };
};
/************************************** changePassword ****************************************************/
export const changePassword = ({ data }) => {
          return dispatch => {
                    return new Promise((resolve, reject) => {
                              apiCallPost(CHANGE_PASSWORD_API, data).then(response => {
                                        const res = response.data;
                                        //console.log('res changePassword::::::', res);
                                        resolve(res);
                              }).catch(error => {
                                if (error?.message == 'Unable to decode data.') {
                                  commonError()
                                } else {
                                        const errorMessage = error.message || error;
                                        //console.log(errorMessage, 'changePassword er::::::', error);
                                        reject(errorMessage);
                                }
                              });
                    });
          };
};
/************************************** logoutAdmin ****************************************************/
export const logoutAdmin = ({ }) => {
          return dispatch => {
                    return new Promise((resolve, reject) => {
                              apiCallGet(CHANGE_PASSWORD_API).then(response => {
                                        const res = response.data;
                                        //console.log('res logoutAdmin::::::', res);
                                        resolve(res);
                              }).catch(error => {
                                        const errorMessage = error.message || error;
                                        //console.log('logoutAdmin er::::::', error);
                                        reject(errorMessage);
                              });
                    });
          };
};
import axios from "axios";
import { BASE_URL } from "./EndPoints";
import { getData } from "./Utils";
import * as Constants from '../constants/constants';
import NodeRSA from 'node-rsa';
import { clearUserCookie, deleteAllCookies, saveData } from "../Services/Utils";
import { REACT_APP_DOMAIN_KEY } from "../Services/EndPoints";

axios.interceptors.request.use(
     async (config) => {
          //console.log('token apiclient::::::::', await getData(Constants.ACCESS_TOKEN))
          config.headers["Authorization"] = await getData(Constants.ACCESS_TOKEN);
          config.headers["Access-Control-Allow-Origin"] = "http://localhost:3001/";
          return config;
     },
     (error) => {
          return error;
     }
);
// ************************************************* Post **************************************************
export const apiCallPost = (url, data1) =>
     new Promise((resolve, reject) => {
          // console.log(`${BASE_URL}${url} post::::`, "url");
          // console.log(data1, "data");
          const data = data1 != null ? encodeData(data1) : null
          axios.post(`${BASE_URL}${url}`, data, { headers: { "Content-Type": "application/json" } }).then((res) => {
               if (res) {
                    resolve(res.data);
               }
          }).catch((error) => {
               if (error?.response?.status == 401) {
                    reject('Session Expired.')
                    return setTimeout(() => {
                         logout();
                    }, 1500);

               } else {
                    const err = error?.response?.data || 'Something went wrong'
                    reject(err);
               }
          });
     });
// ************************************************* Get **************************************************
export const apiCallGet = (url) =>
     new Promise((resolve, reject) => {
          //console.log(`${BASE_URL}${url} get::::`, "url");
          axios.get(`${BASE_URL}${url}`).then((res) => {
               resolve(res.data);
          }).catch((error) => {
               //console.log(error, "error");
               if (error?.response?.status == 401) {
                    reject('Session Expired.')
                    return setTimeout(() => {
                         logout();
                    }, 1500);
               } else {
                    const err = error?.response?.data || 'Something went wrong'
                    reject(err);
               }
          });
     });
// ************************************************* PostFile **************************************************
export const apiCallPostFile = (url, data) =>
     new Promise((resolve, reject) => {
          // console.log(`${BASE_URL}${url} post::::`, "url");
          // console.log(`${data} post:::multipart:`, "data");

          axios.post(`${BASE_URL}${url}`, data, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
               if (res) {
                    resolve(res.data);
               }
          }).catch((error) => {
               //console.log("error is::::", error?.response);
               if (error?.response?.status == 401) {
                    reject('Session Expired.')
                    return setTimeout(() => {
                         logout();
                    }, 1500);

               } else {
                    const err = error?.response?.data || 'Something went wrong'
                    reject(err);
               }
          });
     });
// ************************************************* PostFile **************************************************
export const apiCalGetFile = (url) =>
     new Promise((resolve, reject) => {
          // console.log(`${url} get::::`, "url");
          axios.get(`${url}`, { headers: { "Content-Type": "text/csv" } }).then((res) => {
               if (res) {
                    resolve(res.data);
               }
          }).catch((error) => {
               console.log("error is::::", error?.response);
               if (error?.response?.status == 401) {
                    reject('Session Expired.')
                    return setTimeout(() => {
                         logout();
                    }, 1500);

               } else {
                    const err = error?.response?.data || 'Something went wrong'
                    reject(err);
               }
          });
     });
// ************************************************* encodeData **************************************************
export const encodeData = data => {
     const second = new NodeRSA(Constants.KEY);
     second.setOptions({ encryptionScheme: 'pkcs1' });
     const enc = second.encrypt(data, 'base64');
     const dataa = {
          dataString: enc,
     };
     // console.log('enc::::', dataa);
     return JSON.stringify(dataa);
};
// ************************************************* logout **************************************************
const logout = async () => {
     const theme = await getData(Constants.APP_THEME);
     clearUserCookie("csrfToken", "", 0);
     localStorage.removeItem("_barong_session");
     sessionStorage.clear();
     localStorage.clear();
     deleteAllCookies();
     saveData(Constants.APP_THEME, theme)
     window.location.replace("/" + REACT_APP_DOMAIN_KEY);
}
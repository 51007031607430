import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SubAdmin.scss";
import { Checkbox, Col, Table, Row, Space } from "antd";
import { toast } from "../../Ui/Toast/Toast";
import CheckBoxes from "./checkboxes/index.jsx";
import { PlusCircleOutlined } from "@ant-design/icons";
import ModalCommon from "../ModalCommon/ModalCommon";
import RoleManagmentForm from "./RoleManagmentForm";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { getMenuList, getRoleList, deleteRole, updateRole } from "../../../redux/_actions/subAdminAction";
import InputCustom from "../../Ui/input/InputCustom";
import { SearchOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';
import Paginate from "../../Ui/Paginate/Paginate";
import Swal from "sweetalert2";
import Loader from ".././../../Loader/Loader";

export default function RoleManagment() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const limit = 10;
  const initilaObj = {
    error: {
      name: "",
      menu_list: ""
    },
  };
  const dispatch = useDispatch();
  const [roleName, setRoleName] = useState("");
  const [allState, setAllState] = useState(initilaObj);
  const [currentRow, setCurrentRow] = useState();
  const [isLoading, setLoading] = useState(false);
  const [menulist, setMenuList] = useState();
  const [allMenuList, setAllMenuList] = useState(menulist);
  const [roleData, setRoleData] = useState();
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    fetchMenuList();
    fetchRoleList();
  }, [pageCount, search]);

  const permission = () => {
    if (tab_data) {
      let editPermission = tab_data.find(d => d.name === "Sub Admin");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const handleValidaton = (data) => {
    var error = allState?.error;
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "name":
          if (value === "") {
            error[key] = "Role Name field is required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "menu_list":
          if (value?.length <= 0) {
            error[key] = "Select atleast one menu";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };
  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    return sortedArr1.every((element, index) => element === sortedArr2[index]);
  }
  const updateRoleById = async () => {
    let selected_ids = allMenuList.filter(d => d.checked == true).map(menu_list => `${parseInt(menu_list.id)}`);
    let oldIds = currentRow?.admin_role_menu_list_data?.map(menu_list => `${parseInt(menu_list.admin_menu_list_data.id)}`);
    let isValid = handleValidaton({
      name: roleName,
      menu_list: selected_ids
    });
    if (isValid) {
      setLoading(true);
      const data = {
        role: roleName === "" ? currentRow?.role_name : roleName.trim(),
        menu_list_ids: selected_ids,
        role_id: currentRow.id
      }
      let message = "";
      if (arraysAreEqual(selected_ids, oldIds)) {
        if (currentRow?.role_name.trim() !== roleName.trim()) {
          message = "Role Name has changed successfully!";
        }
      } else {
        message = "Role updated successfully!";
      }
      if (message != "") {
        setTimeout(() => {
          dispatch(updateRole({ data })).then(response => {
            fetchRoleList();
            handleEditCancel();
            setRoleName("");
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              confirmButtonColor: '#d3d3d3',
              text: message,
              color: '#7D80DF',
            });

            setLoading(false)
          }).catch(err => {
            toast.error(err)
            setLoading(false)
          })
        }, 100);
      }
      else {
        handleEditCancel();
        setLoading(false);
      }
    }
  }

  const updateDataOnly = async (item) => {
    await updateData();
    let temp = [...menulist]
    for (let i = 0; i < temp.length; i++) {
      let idexists = item?.admin_role_menu_list_data?.find(d => d.admin_menu_list_data.id === temp[i].id);
      if (idexists) {
        temp[i].checked = true;
      }
    }
    setRoleName(item?.role_name)
    setAllMenuList(temp);
  }
  const handleCheck = (item, status) => {
    let temp = [...allMenuList]
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === item) {
        temp[i].checked = status;
      }
    }
    setAllMenuList(temp);
    handleValidaton({ menu_list: temp })
  }

  const fetchMenuList = () => {
    setTimeout(() => {
      dispatch(getMenuList({})).then(response => {
        for (let i = 0; i < response.length; i++) {
          response[i].checked = false;
        }
        setMenuList(response);
      }).catch(err => {
        toast.error(err)
      })
    }, 100);
  }
  const fetchRoleList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    setTimeout(() => {
      dispatch(getRoleList({ data })).then(response => {
        const res = response.data;
        setRoleData(res);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages)
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }

  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }

  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };

  /**************************************delete role****************************************************/
  const deleteRoleById = (item) => {
    setLoading(true);
    const data = {
      role_id: item.id,
    }
    setTimeout(() => {
      dispatch(deleteRole({ data })).then(response => {
        fetchRoleList();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: response,
          color: '#7D80DF',
        })
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  };

  /************************************** table data ****************************************************/
  const getAccessRows = (admin_role_menu_list_data) => {
    return admin_role_menu_list_data?.map(menu_list => `${menu_list.admin_menu_list_data.menu_list}`).join(' | ')
  }
  const dataSource = roleData?.map((item, index) => {
    return (
      {
        key: index + 1,
        SNo: (<label className="ml-15">{(((metaData?.page - 1) * limit) + (index + 1))}</label>),
        role: item.role_name,
        access: (
          <div className="tableInineFlexed">
            {getAccessRows(item?.admin_role_menu_list_data)}
          </div>
        ),
        actions: <div className="tableInineFlexed"><ButtonCustom disabled={!permission()} label="Edit" onClick={() => { showEditModal(item) }} lytgaryBtn /><ButtonCustom
          onClick={() => {
            Swal.fire({
              title: `Are you sure you want to delete role?`,
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: '#d3d3d3',
              confirmButtonText: 'Yes',
              cancelButtonText: 'NO'
            }).then((result) => {
              if (result.isConfirmed) {
                deleteRoleById(item)
              }
            });
          }}
          disabled={!permission()}
          label="Delete" lytgaryBtn /></div>
      }
    )
  });

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const showModal = () => {
    updateData();
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (item) => {
    setCurrentRow(item)
    updateDataOnly(item);
    setIsEditModalOpen(true);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  const updateData = async () => {
    for (let i = 0; i < menulist.length; i++) {
      menulist[i].checked = false;
    }
    setMenuList(menulist);
  }
  //
  return (
    <div className="userAccess">
      <div className="userAccess_head">
        <button className="userAccess_head_addBtn" onClick={showModal}>
          {permission() &&
            <PlusCircleOutlined />
          }
        </button>
        <div className="annoucements_block_multiData">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          </div>
        </div>
      </div>

      <div className="userAccess_body">
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </div>
      {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      <ModalCommon
        headTittle={"Role Management Form"}
        isModalVisible={isModalOpen}
        handleCancel={handleCancel}
      >
        <div className="modalApp">
          <RoleManagmentForm handleCancel={handleCancel} fetchRoleList={fetchRoleList}
            menulist={menulist} />
        </div>
      </ModalCommon>
      <ModalCommon
        headTittle={"Role Management Form"}
        isModalVisible={isEditModalOpen}
        handleCancel={handleEditCancel}
      >
        <div className="modalApp">
          <div className="userAcessForm">
            <Row gutter={[24, 24]}>
              {/* ----------------------------------------------------------- */}
              <Col xs={24}>
                <InputCustom
                  label="Name of Role"
                  basicInput
                  inputCustum
                  eyeicon
                  placeholder={currentRow?.role_name}
                  value={roleName}
                  onChange={(e) => {
                    setRoleName(e.target.value);
                    if (e.target.value !== "") {
                      handleValidaton({
                        name: e.target.value
                      });
                    }
                  }}
                  maxLength={100}
                />
                <p className="error">{allState.error.name}</p>
              </Col>
              {/* ----------------------------------------------------------- */}
              <Col xs={24}>
                <label className="formLabel">Access</label>
                <div className="radioGroupDiv">
                  <Space direction="vertical">
                    <Checkbox.Group
                      style={{
                        width: "100%",
                      }}
                    >
                      {allMenuList?.map((item, index) => {
                        console.log("hihhsdfasdf", item);
                        return (
                          <CheckBoxes
                            checked={item.checked}
                            labelText={item.menu_list}
                            value={item.id}
                            onChange={(e) => {
                              let ischecked = e.target.checked;
                              if (ischecked) {
                                if (item.menu_list === "Sub Admin") {
                                  Swal.fire({
                                    title: `Are you sure you want to give permission for Role Management?`,
                                    icon: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: '#d3d3d3',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'NO'
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleCheck(item.id, ischecked)
                                    }
                                  });
                                } else {
                                  handleCheck(item.id, ischecked)
                                }
                              }
                              else {
                                handleCheck(item.id, ischecked)
                              }
                            }}
                          />
                        )
                      }
                      )}
                    </Checkbox.Group>
                    <p className="error">{allState.error.menu_list}</p>
                  </Space>
                </div>
              </Col>
              {/* ----------------------------------------------------------- */}
              <Col xs={24}>
                <ButtonCustom
                  onClick={() => {
                    updateRoleById();
                  }}
                  label="Update" lytgaryBtn />
              </Col>
            </Row>
            {/* ----------------------------------------------------------- */}
            <Loader isLoading={isLoading} />
          </div>
        </div>
      </ModalCommon>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}

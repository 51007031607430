import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Table } from "antd";
import { Link } from "react-router-dom";
import { BASE_IMAGE_URL } from "../../../Services/EndPoints";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import Loader from "../../../Loader/Loader";
import { SearchOutlined } from "@ant-design/icons";
import "./ApprovalRecquest.scss";
import Paginate from "../../Ui/Paginate/Paginate";
import InputCustom from "../../Ui/input/InputCustom";
import { toast } from "../../Ui/Toast/Toast";
import Swal from "sweetalert2";
import { getApprovalRequestList, updateStatusRequest } from "../../../redux/_actions/referralAction";
import debounce from 'lodash.debounce';

export default function ApprovalRecquest() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Current level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: " Up Grade Level",
      dataIndex: "grade",
      key: "arade",
    },

    {
      title: "Deposit Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Agreement",
      dataIndex: "agreement",
      key: "agreement",
    },
    {
      title: "No. of Referrals",
      dataIndex: "referral",
      key: "referral",
    },


    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    console.log('chk search:::::', search)
    fetchReferralList();
  }, [pageCount, search]);

  const permission = () => {
    if (tab_data) {
      let editPermission = tab_data.find(d => d.name === "Approval Request");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }


  const fetchReferralList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    setTimeout(() => {
      dispatch(getApprovalRequestList({ data })).then(response => {
        const res = response.data;
        setUserData(res);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return (
      {
        key: index + 1,
        SNo: (<label className="ml-15">{(((metaData?.page - 1) * limit) + (index + 1))}</label>),
        name: item?.AdminApproval_user_data?.user_name,
        email: item?.AdminApproval_user_data?.email,
        level: item?.AdminApproval_ReferalTypes_data?.current_level,
        grade: item?.AdminApproval_nextLevelReferalTypes_data?.upgrade_level,
        action: (
          <div className="agreementBtn">
            {item?.status === 2 ? <label>Approved</label> :
              <>
                <button
                  disable={(item?.status === 4 || !permission())}
                  onClick={() => {
                    if (item?.status !== 4 && permission()) {
                      Swal.fire({
                        title: `Are you sure you want to approve request?`,
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: '#d3d3d3',
                        // cancelButtonColor: '#bec3ca',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'NO'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          updateStatus(item?.id, 2, item?.user_id, item?.next_level_referral_type_id)
                        }
                      });
                    }
                  }}
                  className={`${(item?.status === 4 || !permission()) ? "disabled" : ""}`}
                >Approve</button>
                <button
                  disable={(item?.status === 3 || item?.status === 4 || !permission())}
                  onClick={() => {
                    if ((item?.status !== 3 && item?.status !== 4 && permission())) {
                      Swal.fire({
                        title: `Are you sure you want to reject request?`,
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: '#d3d3d3',
                        // cancelButtonColor: '#bec3ca',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'NO'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          updateStatus(item?.id, 3, item?.user_id, item?.next_level_referral_type_id)
                        }
                      });
                    }
                  }}
                  className={`declineBtn ${(item?.status === 3 || item?.status === 4 || !permission()) ? "disabled" : ""}`}
                > {item?.status === 3 ? "Declined" : "Decline"}</button></>}
          </div>
        ),
        agreement: (<label className="ml-50">{(<DownloadOutlined
          onClick={() => {
            const link = `${BASE_IMAGE_URL}${item?.agreement}`
            window.open(link);
          }}
        />)}</label>),
        referral: (<label className="ml-50">{item?.combo_referrals ?? 0}</label>),
        amount: (<label className="ml-50">{(item?.amount_in_crypto ?? 0) + " USDT"}</label>),
      }
    )
  });
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  /**************************************update request****************************************************/
  const updateStatus = (id, status, user_id, next_level_referral_type_id) => {
    setLoading(true);
    const data = {
      id: id,
      status: status,
      user_id: user_id,
      next_level_referral_type_id: next_level_referral_type_id
    }
    setTimeout(() => {
      dispatch(updateStatusRequest({ data })).then(response => {
        fetchReferralList();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: response,
          color: '#7D80DF',
        })
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  return (
    <div>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Approval Request</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="commonPadding userDatils Referrals">
        <div className="annoucements_block_multiData">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
        {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      </div>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}

import React, { useEffect } from 'react'
import CardDetailsControl from './CardDetailsControl'
import { Col, Row } from 'antd';
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import InputCustom from '../../Ui/input/InputCustom';
import { useState } from 'react';
import { Button, Modal, Breadcrumb } from 'antd';
import { getHyperCardBal, getLiminalBal, getSupportedCoins, updateFee } from '../../../redux/_actions/cardAction';
import CheckboxCustom from '../../Ui/checkbox/CheckboxCustom';
import * as Constants from '../../../constants/constants';
import { ButtonCustom } from '../../Ui/button/ButtonCustom';
import { toFixedExp } from '../../../Services/Utils';
import Swal from 'sweetalert2';

function CardControl() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);

  const dispatch = useDispatch();
  const [isOpen, setIsModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currencyList, setCurrencyList] = useState();
  const [cardMarkup, setCardMarkup] = useState('0.00');
  const [depositMarkup, setDepositMarkup] = useState('0.00');
  const [TxnMarkup, setTxnMarkup] = useState('0.00');
  const [cardBalList, setCardBalList] = useState([]);

  useEffect(() => {
    getSupported_Coins();
    fetchLiminalBal();
  }, [])
   /************************************** Permission ****************************************************/
  const permission = () => {
    if(tab_data){
      let editPermission = tab_data.find(d => d.name === "Card Control");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else{
      return true;
    }
  }

  /************************************** fetchLiminalBal ****************************************************/
  const fetchLiminalBal = () => {
    setLoading(true)
    dispatch(getLiminalBal()).then(res => {
      //  console.log('chk res liminal bal::::', res);
      if (res.length > 0) {
        res.map((item, index) => {
          item.image = item?.token_image
          item.balance = item?.liminal_balance
          item.token_symbol = item?.symbol
          item.title = 'Liminal'
        })
        setTimeout(() => {
          fetchHyperCardBal(res);
        }, 1000);
        //  console.log('chk data liminal ::::::', res)
      } else fetchHyperCardBal([]);
    }).catch(err => {
      toast.error(err)
      console.log('chk err liminal bal::::', err);
      setLoading(false);
    })
  }
  /************************************** fetchHyperCardBal ****************************************************/
  const fetchHyperCardBal = (liminalData) => {
    dispatch(getHyperCardBal()).then(res => {
      // console.log('chk res hypercard bal::::', res);
      if (res.length > 0) {
        res.map((item, index) => {
          item.image = item?.token_image
          item.balance = item?.value
          item.token_symbol = item?.currency
          item.title = 'Hypercard'
        })
        let list = liminalData ? liminalData.concat(res) : res;
        setCardBalList(list);
        // console.log('chk data hyper ::::::', list)
      } else setCardBalList([])
      setLoading(false)
    }).catch(err => {
      toast.error(err)
      console.log('chk err hypercard bal::::', err);
      setLoading(false)
    })
  }
  /************************************** getSupported_Coins ****************************************************/
  const getSupported_Coins = () => {
    dispatch(getSupportedCoins()).then(res => {
      // console.log('chk resp supported coins:::::', res);
      const list = res?.card_supported_coins
      if (list?.length > 0) {
        let data = list.find((item, index) => item.is_fee == 1);
        setCardMarkup(res?.cards[0]?.card_fee);
        setDepositMarkup(data?.markup_fee);
        setTxnMarkup(data?.tax_fee)
        setCurrencyList(list);
      } else {
        setCurrencyList();
      }
    }).catch(err => {
      console.log('chk err supported coins:::::', err)
    })
  }
  /************************************** showModal ****************************************************/
  const showModal = () => {
    setIsModalOpen(true);
  };
  /************************************** handleOk ****************************************************/
  const handleOk = () => {
    setIsModalOpen(false);
  };
  /************************************** handleCancel ****************************************************/
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  /************************************** onChange ****************************************************/
  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
  };
  /************************************** onChange1 ****************************************************/
  const onChange1 = (checkedValues) => {
    console.log('checked = ', checkedValues);
  };
  /************************************** updateFee ****************************************************/
  const update_Fee = () => {
    // console.log('cardMarkup::::::', cardMarkup, 'DepositMarkup::::::', depositMarkup, 'txnMArkup:::::', TxnMarkup)
    if (cardMarkup?.length == 0 || depositMarkup?.length == 0 || TxnMarkup?.length == 0) {
      return toast.error('All fields are mandatory!!');
    } else if (isNaN(cardMarkup) || isNaN(depositMarkup) || isNaN(TxnMarkup)) {
      return toast.error('Enter valid value');
    } else if (parseFloat(cardMarkup) < 0 || parseFloat(cardMarkup) > 100) {
      return toast.error('Card fee markup must not be less than 0 or greater than 100');
    } else if (parseFloat(depositMarkup) < 0 || parseFloat(depositMarkup) > 100) {
      return toast.error('Deposit fee markup must not be less than 0 or greater than 100');
    } else if (parseFloat(TxnMarkup) < 0 || parseFloat(TxnMarkup) > 100) {
      return toast.error('Transaction fee markup must not be less than 0 or greater than 100');
    } else {
      const data = {
        card_fee: parseFloat(cardMarkup),
        mark_up_fee: parseFloat(depositMarkup),
        trnx_fee: parseFloat(TxnMarkup)
      }
      setLoading(true);
      dispatch(updateFee({ data })).then(res => {
        //console.log('chk update fee resp:::::', res)
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Fee updated successfully!',
          confirmButtonColor: '#d3d3d3'
        });
      }).catch(err => {
        console.log('chk update fee err::::', err);
        toast.error(err)
        setLoading(false);
      })
    }
  }
  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Card control</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className='commonPadding'>
        {/* {cardBalList?.length > 0 ? <h3 className='heading_card'>Card control</h3> : null} */}
        <div className='mainCard'>
          {cardBalList?.length > 0 && <div className='mainCard__cardsgroup'>
            {cardBalList?.map((item, index) => {
              return (<CardDetailsControl curencyImg={item?.image} Currency={item?.token_symbol} Balance={toFixedExp(item.balance, 6)} liminal={item?.title} />)
            })}
          </div>
          }
          <div style={{ marginTop: cardBalList?.length > 0 ? 50 : 0 }} className='mainCard__currency_check'>
            {/* ----------------------------------------------------------- */}
            <h3>Currency Allowed for Card Fee</h3>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              {currencyList?.map((ele, index) => (
                <CheckboxCustom checked={ele.is_fee} key={index} checkbox changeStyle checkboxTxt={(ele.is_token == 1 && ele.coin_family == 6) ? ele.coin_symbol + ' (TRX)' : (ele.is_token == 1 && ele.coin_family == 2) ? ele.coin_symbol + ' (ETH)' : ele.coin_symbol} />
              ))}
            </div>
            {/* ----------------------------------------------------------- */}
            <h3 style={{ marginTop: 50 }}>Currency Allowed for Topup</h3>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              {currencyList?.map((ele, index) => (
                <CheckboxCustom checked={ele.is_fee} key={index} checkbox changeStyle checkboxTxt={(ele.is_token == 1 && ele.coin_family == 6) ? ele.coin_symbol + ' (TRX)' : (ele.is_token == 1 && ele.coin_family == 2) ? ele.coin_symbol + ' (ETH)' : ele.coin_symbol} />
              ))}
            </div>

            <div className='input_fildes'>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div >
                    <InputCustom basicInput inputCustum
                      maxLength={10}
                      label="Card Fee markup"
                      placeholder={'0.00'}
                      value={cardMarkup}
                      onChange={(e) => Constants.NUMBER_DECIM_REGEX.test(e.target.value) && setCardMarkup(e.target.value)}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div >
                    <InputCustom basicInput inputCustum
                      label="Deposit fee markup"
                      placeholder={'0.00'}
                      onChange={(e) => Constants.NUMBER_DECIM_REGEX.test(e.target.value) && setDepositMarkup(e.target.value)}
                      value={depositMarkup}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div >
                    <InputCustom basicInput inputCustum
                      label="Transaction fee markup"
                      placeholder={'0.00'}
                      onChange={(e) => Constants.NUMBER_DECIM_REGEX.test(e.target.value) && setTxnMarkup(e.target.value)}
                      value={TxnMarkup}
                    /></div>
                </Col>

              </Row>
              <div className='update_btn'>
                <ButtonCustom disabled={!permission()} onClick={() => update_Fee()} yellowBtn label="Update" />
              </div>
            </div>

          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
      {/* ----------------------------------------------------------- */}
      <Modal centered className='modalchaqes' visible={isOpen} onOk={handleOk} onCancel={handleCancel}>
        <h2>Do you want to make changes to list APP currency list</h2>
        <div className='modal_btns'>
          <Button >Yes</Button>
          <Button>No</Button>
        </div>
      </Modal>
    </>
  )
}

export default CardControl
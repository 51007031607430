import React from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import "./Inputstyle.scss";
function InputCustom(props) {
  const {
    textInput,
    maxLength,
    fileInput,
    eyeicon,
    passwordInput,
    basicInput,
    inputInnerTxt,
    innerTxt,
    placeholder,
    inputInnerLargeTxt,
    innerTxtImg,
    innerImg,
    searchImg,
    inputSearch,
    label,
    labelcustom,
    InputCustomStyle,
    fillBg,
    onChange,
    value,
    disabled,
    editText,
    onClickDiv,
    showView,
  } = props;
  return (
    <div className="inputLayout ">

      {basicInput ? (
        <>
          {label ? (
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
              <label className={`label ${labelcustom}`}>{label}</label>
              {editText && <div onClick={onClickDiv} style={{ justifyContent: 'flex-end' }} className={`label ${labelcustom} ${showView ? 'texteditLight' : 'textedit'}`}>{editText}</div>}
            </div>
          ) : null}
          <Input maxLength={maxLength || 25} className={`inputCustum ${inputInnerTxt ? "inputInnerTxt" : ""}${inputInnerLargeTxt ? "inputInnerLargeTxt" : ""}${inputSearch ? "inputSearch" : ""} ${InputCustomStyle} ${fillBg}`}
            {...props} placeholder={placeholder}
            onChange={onChange} value={value}
            disabled={disabled}
          />
        </>
      ) : null}

      {innerTxt ? <span className="innerTxt">{innerTxt}</span> : null}
      {innerTxtImg ? (<span className="innerTxtImg">{innerTxtImg}{innerImg}{" "}</span>) : null}
      {searchImg ? <span className="searchImg">{searchImg}</span> : null}

      {passwordInput ? (
        <>
          {label ? <label className="label">{label}</label> : null}
          <Input.Password
            {...props}
            maxLength={maxLength || 25}
            placeholder={placeholder}
            className={`inputCustum ${eyeicon ? "eyeicon" : ""}`}
            iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
          />
        </>
      ) : null}

      {textInput ? (
        <>
          {label ? <label className="label">{label}</label> : null}
          <Input.TextArea
            {...props}
            rows={4}
            maxLength={maxLength || 25}
            placeholder={placeholder}
            className={`inputCustum`}
            iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
          />
        </>
      ) : null}

      {fileInput ? (<>
        {label ? (
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <label className={`label1 ${labelcustom}`}>{label}</label>
            {editText && <div onClick={onClickDiv} style={{ justifyContent: 'flex-end' }} className={`label1 ${labelcustom} ${showView ? 'texteditLight' : 'textedit'}`}>{editText}</div>}
          </div>
        ) : null}
      </>) : null}
    </div>
  );
}

export default InputCustom;

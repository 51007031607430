import { Grid } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./Header.scss";
import { clearUserCookie, deleteAllCookies, getData, saveData } from "../../../Services/Utils";
import * as Constants from '../../../constants/constants';
import { useDispatch } from "react-redux";
import { logoutApp } from "../../../redux/_actions/LoginAction";
import { REACT_APP_DOMAIN_KEY } from "../../../Services/EndPoints";

function RightNavbar() {
  const dispatch = useDispatch();
  const [login, setlogin] = useState(true);
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();
  /************************************** Logout ****************************************************/
  const logoutPress = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: '#d3d3d3',
      // cancelButtonColor: '#bec3ca',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  }
  /************************************** confirm Logout ****************************************************/
  const logout = async () => {
    const theme = await getData(Constants.APP_THEME);
    clearUserCookie("csrfToken", "", 0);
    localStorage.removeItem("_barong_session");
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    saveData(Constants.APP_THEME, theme)
    // dispatch(logoutApp({}));
    window.location.replace("/"+REACT_APP_DOMAIN_KEY);
  };
  return (
    <div onClick={() => logoutPress()} className="headerLinkStyles">
      {login && (
        <React.Fragment >
          <Link to={"#"} className="headerLinkStyles__links">
            {/* <BellIcon /> */}
            <span className="fontFamilyText" >Logout</span>
          </Link>
        </React.Fragment>
      )}
    </div>
  );
}

export default RightNavbar;

import { Breadcrumb, Grid } from 'antd'
import React, { useEffect, useRef, useState, } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ApexCharts from 'apexcharts';
import './UserDetails.scss'
import UserDetailsTab from './UserDetailsTab';
import { getUserData } from '../../../redux/_actions';
import { toast } from '../../Ui/Toast/Toast';
import Loader from '../../../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getValue, toFixedExp } from '../../../Services/Utils';
import ReactApexChart from 'react-apexcharts';

let options = {
  chart: {
    type: 'donut',
    width: '100%',
    height: '350px',
  },
  series: [10, 20, 30, 40, 50],
  labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
  legend: {
    position: 'bottom',
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '75%',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: '16px',
            fontFamily: ` "Poppins", sans-serif`,
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: '14px',
            fontFamily: ` "Poppins", sans-serif`,
            fontWeight: 400,
            color: undefined,
            offsetY: 16,
            formatter: function (val) {
              return "$"+val;
            },
          },
          total: {
            show: true,
            label: 'Total',
            fontSize: '15px',
            fontFamily: ` "Poppins", sans-serif`,
            fontWeight: 600,
            color: undefined,
            formatter: function (w) {
              const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              return "$"+toFixedExp(total, 4);
            },
          },
        },
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  // colors: ['#f9cb28', '#8e4ee9', '#ff4560', '#00b9ff', '#2ed8b6'],
  responsive: [
    {
      // breakpoint: 480,
      options: {
        // chart: {
        //   width: 250,
        // },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};
function UserActivity() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [series, setChartSeries] = useState([0, 0, 0, 0, 0]);
  const { useBreakpoint } = Grid;
  const { xs, sm, md } = useBreakpoint()

  useEffect(() => {
    fetchUserData();
  }, [])

  /**************************************fetchUserData****************************************************/
  const fetchUserData = () => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id
    }
    let series = []
    let labels = []
    setTimeout(() => {
      dispatch(getUserData({ data })).then(response => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => {
            series.push(parseFloat(getValue(item.balance * item.coin?.fiat_price_graph_data?.sparkline[index].price)));
            labels.push(item.coin?.coin_symbol?.toUpperCase())
          });
          options.series = series
          options.labels = labels
          setChartSeries(series)
          // const chart = new ApexCharts(chartRef?.current, options);
          // chart?.render();
          setUserData(res);
        } else {
          setUserData();
        }
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/userDetails">User Details</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            User Activity
          </Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div style={{ marginTop: userData ? 0 : -25 }} className="commonPadding userDatils">
        {userData && <ReactApexChart options={options} series={series} type="donut" width={md ? "500" : sm ? "400" : xs ? '270' : "270"} />}
        {/* <div ref={chartRef}></div> */}
        <UserDetailsTab state={{ user_id: location.state?.user_id }} />
      </div>
      <Loader isLoading={isLoading} />
    </>
  )

}

export default UserActivity

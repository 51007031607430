import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Breadcrumb } from "antd";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import { getUserQueryList, postAdminResponse } from "../../../redux/_actions/contactUsAction";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../Ui/Toast/Toast";
import Loader from "../../../Loader/Loader";
import { BASE_IMAGE_URL } from "../../../Services/EndPoints";
import ModalCommon from "../ModalCommon/ModalCommon";
import Swal from "sweetalert2";

let statusAdmin = ''
function View() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);
  const statusFilter = [
    {
      value: "1",
      label: "In Progress"
    },
    {
      value: "2",
      label: "Resolved"
    },
  ];
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState('');
  const [selectedItem, setItem] = useState('');
  const [selectedStatus, setStatus] = useState('');
  const [adminComment, setAdminComment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchUserQueryData();
  }, []);

  const permission = () => {
    if(tab_data){
      let editPermission = tab_data.find(d => d.name === "Issue Management");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else{
      return true;
    }
  }
  /************************************** fetchUserQueryData ****************************************************/
  const fetchUserQueryData = () => {
    setLoading(true);
    const data = {
      ticket_id: location.state?.ticket_id,
    };
    setTimeout(() => {
      dispatch(getUserQueryList({ data })).then((response) => {
        const res = response
        if (res) {
          if (res.screenshots) {
            const array = JSON.parse("[" + res.screenshots + "]");
            // console.log('chk array res:::::', array)
            res.images = array[0]
          }
          setAdminComment(res?.remarks);
          statusAdmin = res.status
          setStatus(res.status);
          setLoading(false);
          setUserData(res);
          // console.log('chk fetchUserQueryData res:::::', res)
        } else {
          setLoading(false);
          setUserData('');
        }
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });
    }, 100);
  };

  /**************************************handleCancel ****************************************************/
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /**************************************onSubmitPressed****************************************************/
  const onSubmitPressed = () => {
    if (!selectedStatus) return toast.error('Select Status');
    else if (adminComment && adminComment.trim().length < 5) return toast.error('Comment must have length greater than 4');
    else hitRevertApi()
  }

  /**************************************onSubmitPressed****************************************************/
  const hitRevertApi = () => {
    setLoading(true);
    const data = {
      ticket_id: location.state?.ticket_id,
      status: selectedStatus,
      comment: adminComment
    };
    setTimeout(() => {
      dispatch(postAdminResponse({ data })).then((response) => {
        const res = response
        // console.log('chk admin res:::::', res)
        Swal.fire({
          text: "Feedback submitted successfully!",
          icon: "success",
          confirmButtonColor: '#d3d3d3',
          confirmButtonText: "Ok",
        })
        navigate('/contactUs');
        setLoading(false);
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });

    }, 100);
  }

  /**************************************handleSelect****************************************************/
  const handleSelect = (e) => {
    // console.log('chk e::::', e)
    setStatus(e);
  }

  /**************************************************************************************************/
  return (
    <>
      {console.log('statusAdmin:::::', login)}
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Contact Us</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="view">
        <div className="commonPadding">
          <Row gutter={30}>
            {/* ----------------------------------------------------------- */}
            <Col lg={7} xs={24}>
              <InputCustom style={{ textTransform: 'capitalize' }} disabled placeholder={"Enter Name"} maxLength={50} basicInput label="Name" inputCustum value={userData?.name} />
              <InputCustom disabled placeholder={"Enter Message"} maxLength={50} basicInput label="Message" inputCustum value={userData?.message} />
              <InputCustom disabled placeholder={"Enter Transaction Link"} maxLength={50} basicInput label="Transaction Links" inputCustum value={userData?.trnx_links} />
            </Col>
            {/* ----------------------------------------------------------- */}
            <Col lg={7} xs={24}>
              <InputCustom disabled placeholder={"Enter Email"} maxLength={50} basicInput label="Email" inputCustum value={userData?.email} />
              <InputCustom disabled placeholder={"Enter Category"} maxLength={50} basicInput label="Category" inputCustum value={userData?.category} />
              {/* {(statusAdmin == 2 || statusAdmin == 3) ?
                <InputCustom disabled placeholder={"Enter Status"} maxLength={50} basicInput label="Status" inputCustum value={statusAdmin == 2 ? 'Resolved' : 'Expired'} />
                :
                <CustomSelect onChange={(e) => handleSelect(e)} label="Status" defaultData={(statusAdmin == 1 && selectedStatus == 1) ? statusFilter[0].value : (statusAdmin == 2 && selectedStatus == 2) ? statusFilter[1].value : (statusAdmin == 0 && selectedStatus == '') ? null : selectedStatus} className="selector" placeholder="Select" data={statusFilter} />
              } */}
            </Col>
            {/* ----------------------------------------------------------- */}
            <Col lg={9} xs={24}>
              <InputCustom style={{ textTransform: 'capitalize' }} disabled placeholder={"Enter Subject"} maxLength={50} basicInput label="Subject" inputCustum value={userData?.subject} />
              <InputCustom disabled placeholder={"Enter Wallet Address"} maxLength={50} basicInput label="Wallet Address" inputCustum value={userData?.wallet_address} />
            </Col>
            {/* ----------------------------------------------------------- */}
            {userData?.images?.length > 0 && <Col xl={7} lg={7} md={24} xs={24}>
              <div className="upload">
                <label>Screenshots</label>
                <div className="inner">
                  {userData?.images.map((item, index) => {
                    return (<div onClick={() => { setItem(item); setIsModalOpen(true) }}><img src={BASE_IMAGE_URL + item} style={{ borderRadius: 10 }} width={250} height={150} /></div>)
                  })}
                </div>
              </div>
            </Col>
            }
            {/* ----------------------------------------------------------- */}

          </Row>
          {/* <div className="loginScreen__innerInput">
            <InputCustom placeholder={"Enter Comment"} maxLength={70} textInput label=" Admin comments" inputCustum value={adminComment} onChange={(e) => setAdminComment(e.target.value)} />
          </div> */}
          {/* ----------------------------------------------------------- */}
          {/* {((statusAdmin == 2 && selectedStatus == 2) || statusAdmin == 3) ? null : <Row>
            <Col xl={3} xs={5} style={{ marginTop: 30 }}>
              <ButtonCustom style={{ width: 200 }} disabled={true} onClick={() => onSubmitPressed()} label="Submit" yellowBtn />
            </Col>
          </Row>} */}
          {/* ----------------------------------------------------------- */}
          <ModalCommon
            headTittle={"Screenshot"}
            isModalVisible={isModalOpen}
            handleCancel={handleCancel}
          >
            <div className="modalApp">
              <img src={BASE_IMAGE_URL + selectedItem} style={{ borderRadius: 10, alignSelf: 'center', width: 450, height: 500 }} />
            </div>
          </ModalCommon>
          {/* ----------------------------------------------------------- */}
        </div>
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
}

export default View;

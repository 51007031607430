import "./App.scss";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Mainlayout from "./Components/Layout/Mainlayout/Mainlayout";
import Login from "./Components/Pages/Login/Login";
import DashboardLayout from "./Components/Layout/DashboardLayout/DashboardLayout";
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import UserDetails from "./Components/Pages/UserDetails/UserDetails";
import UserActivity from "./Components/Pages/UserDetails/UserActivity";
import AppCustomisation from "./Components/Pages/AppCustomisation/AppCustomisation";
import Annoucements from "./Components/Pages/Annoucements/Annoucements";
import AdminControls from "./Components/Pages/AdminControls/AdminControls";
import { clearUserCookie, deleteAllCookies, getData, saveData } from "./Services/Utils";
import * as Constants from '../src/constants/constants';
import ForgotPassword from "./Components/Pages/ForgotPassword/ForgotPassword";
import ReferralReward from "./Components/Pages/Referral&Reward/ReferralReward";
import { useIdleTimer } from 'react-idle-timer'
import { toast } from "./Components/Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { logoutApp } from "./redux/_actions";
import CardStatus from "./Components/Pages/CardStatus/CardStatus.jsx"
import CardDetails from "./Components/Pages/CardDetails/CardDetails.jsx";
import CardControl from "./Components/Pages/CardControl/CardControl";
import PhysicalCardIssuance from "./Components/Pages/PhysicalCardIssuance/PhysicalCardIssuance";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import View from "./Components/Pages/ContactUs/View";
import Referrals from "./Components/Pages/Referrals/Referrals.js";
import ReferralDetails from "./Components/Pages/Referrals/ReferralDetails.js";
import MasterFranchise from "./Components/Pages/Referrals/MasterFranchise.js";
import ApprovalRecquest from "./Components/Pages/ApprovalRecquest/ApprovalRecquest.js";
import RewardManagement from "./Components/RewardManagement/RewardManagement.js";
import { REACT_APP_DOMAIN_KEY } from "./Services/EndPoints";
import SubAdmin from "./Components/Pages/SubAdmin/SubAdmin.jsx";
import ResetPassword from "./Components/Pages/ResetPassword/ResetPassword";
import { checkPermissions } from "./redux/_actions/dashboardAction";
import CryptoPortfolio from "./Components/Pages/CryptoPortfolio /CryptoPortfolio.jsx";

function App() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.LoginReducer?.userData);
  // let tab_data = login?.admin_user_access_data?.access ? JSON.parse(login?.admin_user_access_data?.access) : "";
  // let role_name = login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "super_admin";
  console.log('chk login11111:::::::', login)

  const onIdle = () => {
    console.log('Idle')
    logout();
  }
  /************************************** confirm Logout ****************************************************/
  const logout = async () => {
    const theme = await getData(Constants.APP_THEME);
    clearUserCookie("csrfToken", "", 0);
    localStorage.removeItem("_barong_session");
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    saveData(Constants.APP_THEME, theme);
    // dispatch(logoutApp({}));
    //navigate("/");
    window.location.replace("/" + REACT_APP_DOMAIN_KEY);
  };

  const logout_on_deleted = async () => {
    clearUserCookie("csrfToken", "", 0);
    localStorage.removeItem("_barong_session");
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    window.location.replace("/" + REACT_APP_DOMAIN_KEY);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 10,
    promptBeforeIdle: 1000 * 30,
  })
  useEffect(() => {
    const themes = getData(Constants.APP_THEME);
    console.log('themes::::::', themes)
    themes == 'darkTheme' ? document.body.classList.add("darkTheme") : document.body.classList.add("lightTheme")
    let urlArray=window.location.href.split("/");
    if (urlArray[urlArray.length-1] === "new_admin") {
      logout();
    }
    if (login) {
      checkAuthorization();
    }
  }, []);
  const checkAuthorization = () => {
    setTimeout(() => {
      dispatch(checkPermissions({})).then(res => {
        console.log("checkAuthorization", res)
      }).catch(err => {
        if ('Unable to decode data.') {
          toast.error("User does not exist");
          logout_on_deleted();
        }else{
          toast.error(err)
        }
      })
    }, 100);
  }
  return (
    <Routes>
      {login ?
        <Route path="/" element={<DashboardLayout />}>
          <Route index path="dashboard" element={<Dashboard />} />
          <Route index path="/" element={<Dashboard />} />
          <Route index path="userDetails" element={<UserDetails />} />
          <Route index path="userDetails/userActivity" element={<UserActivity />} />
          <Route index path="appCustomisation" element={<AppCustomisation />} />
          <Route index path="annoucements" element={<Annoucements />} />
          <Route index path="cardcontrol" element={<CardControl />} />
          <Route index path="contactUs" element={<ContactUs />} />
          <Route index path="contactUs/view" element={<View />} />
          <Route index path="card-details" element={<CardDetails />} />
          <Route index path="card-issuance" element={<PhysicalCardIssuance />} />
          <Route index path="card-details/card-status" element={<CardStatus />} />
          <Route index path="adminControls" element={<AdminControls />} />
          <Route index path="referralReward" element={<ReferralReward />} />
          <Route index path="referrals" element={<Referrals />} />
          <Route index path="referralDetails" element={<ReferralDetails />} />
          <Route index path="masterFranchise" element={<MasterFranchise />} />
          <Route index path="approvalRecquest" element={<ApprovalRecquest />} />
          <Route index path="rewardManagement" element={<RewardManagement />} />
          <Route index path="sub-admin" element={<SubAdmin />} />
          <Route index path="CryptoPortfolio" element={<CryptoPortfolio />} />
        </Route>
        :
        <Route path="/" element={<Mainlayout />}>
          <Route index path="/" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route index path="/new_admin" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      }
    </Routes>
  );
}

export default App;

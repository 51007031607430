import { Breadcrumb, Col, DatePicker, Grid, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./Dashboard.scss";
import DetailsCard from "./DetailsCard";
import ReactApexChart from "react-apexcharts";
import Facebookicon from "../../Assets/AdminImages/Facebookicon.svg";
import GoogleIcon from "../../Assets/AdminImages/GoogleIcon.svg";
import EmailIcon from "../../Assets/AdminImages/EmailIcon.svg";
import userIcon from "../../Assets/AdminImages/UserIcon.svg";
import LinkedInIcon from "../../Assets/AdminImages/LinkedInIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { userChart, userCount } from "../../../redux/_actions";
import { toast } from "../../Ui/Toast/Toast";
import Loader from "../../../Loader/Loader";
import AppleStoreIcon from "../../Assets/AdminImages/ApploLogo.png";
import AppleLogoBlack from "../../Assets/AdminImages/Apple_logo_black.svg.png";
import Liminal from "../../Assets/Images/Liminal.jpg";
import hyperbc from "../../Assets/Images/hyperbc.png";
// import Liminal from "../../Assets/Images/Liminal.jpg";

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { TabPane } = Tabs;

  const login = useSelector((state) => state?.LoginReducer?.userData);
  const dispatch = useDispatch();
  const theme = useOutletContext();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [chart_Data, setChartData] = useState();
  const [series, setChartSeries] = useState([0, 0, 0, 0, 0]);
  const { useBreakpoint } = Grid;
  const { xs, sm, md } = useBreakpoint();
  const [activeKey, setActiveKey] = useState("1");

  const options = {
    chart: {
      width: md ? "500" : sm ? "400" : xs ? "300" : "300",
      type: "donut",
    },
    labels: ["Google", "Facebook", "Email", "Apple", "LinkedIn"],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: "gradient",
    },
    plotOptions: {
      pie: {
        donut: {
          size: md ? "50%" : sm ? "50%" : xs ? "60%" : "40%",
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    fetchUserCount();
    // fetchchartData();
  }, []);
  /************************************** fetchchartData ****************************************************/
  const fetchchartData = () => {
    const data = {
      filter: "Daily",
    };
    setLoading(true);
    setTimeout(() => {
      dispatch(userChart({ data }))
        .then((res) => {
          setLoading(false);
          setChartData(res);
        })
        .catch((err) => {
          setLoading(false);
        });
    }, 100);
  };
  /************************************** fetchUserCount ****************************************************/
  const fetchUserCount = () => {
    setLoading(true);
    let arr = [];
    setTimeout(() => {
      dispatch(userCount({}))
        .then((res) => {
          arr.push(res?.Google_users);
          arr.push(res?.Facebook_users);
          arr.push(res?.Email_users);
          arr.push(res?.Apple_users);
          arr.push(res?.linkedinUsers);
          setChartSeries(arr);
          setUserData(res);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  const handleTabChange = (key) => {
    // active_key = key
    // setLocalValue('');
    // setSearch('');
    // setSearchToken('');
    // setPageCountToken('');
    // setPageCount('');
    setActiveKey(key);
  };
  return (
    <>
      <div className="breadcrumb assigned ">
        <Breadcrumb>
          <Breadcrumb.Item>Portfolio</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="dashboardScreen commonPadding">
        <div className="dashboardScreen_totalUsers">
          <div className="responsive">
            {/* ----------------------------------------------------------- */}
            {/* <Row gutter={[15, 15]}> */}
            {/* <Col
              className="responsive" */}
            {/* // xs={{ span: 24 }}
              // sm={{ span: 12 }}
              // lg={{ span: 12 }} */}
            {/* > */}
            {/* <div className="dashboardScreen__reward"> */}
            <DetailsCard
              keyname={"Google Signups"}
              Valname={userData?.Google_users || 0}
              icon={GoogleIcon}
            />
            {/* </div> */}
            {/* </Col> */}
            {/* ----------------------------------------------------------- */}
            {/* <Col
              className="responsive" */}
            {/* // xs={{ span: 24 }}
              // sm={{ span: 12 }}
              // lg={{ span: 12 }} */}
            {/* > */}
            {/* <div className="dashboardScreen__reward"> */}
            <DetailsCard
              keyname={"Facebook Signups"}
              Valname={userData?.Facebook_users || 0}
              icon={Facebookicon}
            />
            {/* </div> */}
            {/* </Col> */}
            {/* ----------------------------------------------------------- */}
            {/* <Col
              className="responsive" */}
            {/* // xs={{ span: 24 }}
              // sm={{ span: 12 }}
              // lg={{ span: 12 }}
            > */}
            {/* <div className="dashboardScreen__reward"> */}
            <DetailsCard
              keyname={"Email Signups"}
              Valname={userData?.Email_users || 0}
              icon={EmailIcon}
            />
            {/* </div> */}
            {/* </Col> */}
            {/* ----------------------------------------------------------- */}
            {/* <Col
              className="responsive" */}
            {/* // xs={{ span: 24 }}
              // sm={{ span: 12 }}
              // lg={{ span: 12 }}
            > */}
            {/* <div className="dashboardScreen__reward"> */}
            <DetailsCard
              keyname={"Apple Signups"}
              Valname={userData?.Apple_users || 0}
              icon={theme === "darkTheme" ? AppleStoreIcon : AppleLogoBlack}
            />
            {/* </div> */}
            {/* </Col> */}
            {/* ----------------------------------------------------------- */}
            {/* <Col
              className="responsive" */}
            {/* // xs={{ span: 24 }}
              // sm={{ span: 12 }}
              // lg={{ span: 12 }}
            > */}
            {/* <div className="dashboardScreen__reward"> */}
            <DetailsCard
              keyname={"LinkedIn Signups"}
              Valname={userData?.linkedinUsers || 0}
              icon={LinkedInIcon}
            />
            {/* </div> */}
            {/* </Col>
          </Row> */}
          </div>
          {/* ----------------------------------------------------------- */}
          {userData?.total_records == 0 && (
            <Col
              className="responsive"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 12 }}
              style={{ marginBottom: 20 }}
            >
              <DetailsCard
                keyname={"Total Users"}
                Valname={userData?.total_records}
                icon={userIcon}
              />
            </Col>
          )}
          <div className="dashboardScreen_middleCard">
            {userData?.total_records > 0 && (
              <Row align="middle">
                <div
                  className="dashboardScreen__chartSec"
                  style={{
                    marginTop: -1,
                    marginBottom: 15,
                    alignItems: "center",
                  }}
                >
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="donut"
                    width={md ? "480" : sm ? "400" : xs ? "300" : "300"}
                  />
                </div>
              </Row>
            )}
            <div className="balanceCard">
              <div className="balanceCard_card hide">
                <ul style={{ color: "#000" }}>
                  <li>
                    <img src={Liminal} width={50} alt="Liminal logo" />
                    <div>
                      <p>
                        Liminal Balance : <span> $100</span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="balanceCard_card hide">
                <ul style={{ color: "#000" }}>
                  <li>
                    <img src={hyperbc} width={50} alt="hyperbc logo" />
                    <div>
                      <p>
                        Hypercard Balance : <span> $500</span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------- */}
          <Loader isLoading={isLoading} />
        </div>
        {/* {/* <h3>Revenue</h3> */}

        <div className="tabs__body portfolioTabs hide">
          <Tabs
            activeKey={activeKey}
            style={{ marginTop: 15, display: "flex" }}
            onChange={handleTabChange}
            defaultActiveKey="1"
          >
            <TabPane tab="Fiat Portfolio" key="1">
              <div className="overallCrypto ">
                <h4>Total Fiat Revenue Earned $10,000</h4>
                <div className="overallCrypto_detail">
                  <div className="overallCrypto_detail_link">
                    <DatePicker
                      status="error"
                      style={{
                        width: "100%",
                      }}
                    />
                    <Link to={"/CryptoPortfolio"}>View Detailed Data</Link>
                  </div>
                  <div className="cards_detail">
                    <ul style={{ color: "#000" }}>
                      <li>
                        <p> Profit earned from OTC</p>
                        <p>$40</p>
                      </li>
                      <li>
                        <p>Profit earned from Virtual card deposits</p>
                        <p>$100</p>
                      </li>
                      <li>
                        <p>Profit earned from Virtual card transactions</p>
                        <p>$100</p>
                      </li>
                      <li>
                        <p>Profit earned from Physical card deposits</p>
                        <p>$500</p>
                      </li>

                      <li>
                        <p>Profit earned from Physical card transactions</p>
                        <p>$260</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Crypto Portfolio" key="2">
              <div className="overallCrypto">
                <h4>Total Crypto Revenue Earned $7,000</h4>
                <div className="overallCrypto_detail">
                  <div className="overallCrypto_detail_link">
                    <DatePicker
                      status="error"
                      style={{
                        width: "100%",
                      }}
                    />
                    <Link to={"/CryptoPortfolio"}>View Detailed Data</Link>
                  </div>
                  <div className="cards_detail ">
                    <ul style={{ color: "#000" }}>
                      <li>
                        <p>Profit earned from on - chain swap</p>
                        <p>None</p>
                      </li>
                      <li>
                        <p>Profit earned from cross - chain swap</p>
                        <p>$100</p>
                      </li>
                      <li>
                        <p>Profit earned from On/off ramp (Alchemy) </p>
                        <p>$100</p>
                      </li>

                      <li>
                        <p>Profit earned from On/off ramp (Transak)</p>
                        <p>$500</p>
                      </li>
                      <li>
                        <p>Profit earned from OTC purchase</p>
                        <p>None</p>
                      </li>
                      <li>
                        <p>Profit earned from STO investment</p>
                        <p>None</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default Dashboard;

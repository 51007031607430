import CryptoJS from "crypto-js";
import * as Constants from '../constants/constants';
import TronWeb from 'tronweb';
import NodeRSA from "node-rsa";

// ************************************************* saveData **************************************************
export const saveData = (key, value) => {
  //console.log('Save Data :::::::', key, value, JSON.stringify(value));
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), Constants.APP_NAME).toString();
  //console.log('Save Data ciphertext:::::::', ciphertext);
  sessionStorage.setItem(key, ciphertext);
}
// ************************************************* getData **************************************************
export const getData = (key) => {
  try {
    const obj = sessionStorage.getItem(key);
    if (obj) {
      const bytes = CryptoJS?.AES.decrypt(obj, Constants.APP_NAME);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log(decryptedData,"decryptedData")
      return decryptedData;
    }
  } catch (err) {
    //console.log('Get Data err:::::::', err);
  }
}
// ************************************************* decodeData **************************************************
export const decodeData = async data => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("data::::", data);
      const key = new NodeRSA(Constants.CARD_KEY);
      key.setOptions({ encryptionScheme: "pkcs1" });
      const decrypted = await key.decrypt(data, "utf8");
      let originalText = await JSON.parse(decrypted);
      // console.log("originalText:::", originalText);
      resolve(originalText);
    } catch (err) {
      console.log("errr:::::", err)
      reject(err)
    }
  })
};
// ************************************************* clearUserCookie **************************************************
export function clearUserCookie(cname, cvalue, exMins) {
  const d = new Date();
  d.setTime(d.getTime() + exMins * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}
// ************************************************* deleteAllCookies **************************************************
export function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
/************************************** Validate TRON Address ************************************/
export const validateTRXAddress = text => {
  //console.log('chk Valid trx Addresss::::::::::::', text);
  return TronWeb.isAddress(text);
};
/************************************** maskAddress ************************************/
export const maskAddress = (address) => {
  const a = address?.slice(0, 10);
  const b = address?.slice(-10);
  return a + '...' + b;
}
/************************************************** toFixedExp **************************************************************/
export function toFixedExp(num, fixed) {
  if (num) {
    num = exponentialToDecimal(num);
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }
  else return '0.00';
}
/************************************** getValue ****************************************************/
export const getValue = (bal) => {
  if (bal > 0) {
    const NewBal = bal < 0.000001 ? toFixedExp(bal, 8) : bal < 0.0001 ? toFixedExp(bal, 6) : toFixedExp(bal, 4)
    return NewBal
  } else return '0.0000'
}
/************************************************** exponentialToDecimal ***************************************************/
export const exponentialToDecimal = (exponential) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes('e+')) {
    const exponentialSplitted = decimal.split('e+');
    let postfix = '';
    for (let i = 0; i < +exponentialSplitted[1] - (exponentialSplitted[0].includes('.') ? exponentialSplitted[0].split('.')[1].length : 0); i++) {
      postfix += '0';
    }
    const addCommas = text => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)}${text.slice(textLength - j, textLength,)}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
  }
  if (decimal.toLowerCase().includes('e-')) {
    const exponentialSplitted = decimal.split('e-');
    let prefix = '0.';
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += '0';
    }
    decimal = prefix + exponentialSplitted[0].replace('.', '');
  }
  return decimal;
}
//******************************************** getStatus **************************************************/
export const getStatus = (item) => {
  const trx_type = item.type?.toLowerCase();
  const status = item.status?.toLowerCase();
  const blockChain_status = item.blockchain_status != null ? item.blockchain_status?.toLowerCase() : item?.blockchain_status;
  // console.log('trx_type:::::', trx_type, 'status:::::', status, 'blockChain_status::::', blockChain_status)
  if (status == 'failed' || blockChain_status == 'failed') {
    return 'Failed';
  } else if (trx_type == 'withdraw' || trx_type == 'approve' || trx_type == 'cross_chain' || trx_type == 'swap') {
    if (blockChain_status == 'confirmed' && status == 'completed') {
      return 'Completed';
    }
    if ((blockChain_status == null || blockChain_status == 'pending') && status == 'completed') {
      return 'Pending';
    }
    if (blockChain_status == null && status == 'unconfirmed') {
      return 'Pending';
    }
  } else if (trx_type != 'withdraw') {
    if (status == 'completed') {
      return 'Completed';
    }
    if (status == 'unconfirmed') {
      return 'Pending';
    }
  }
}
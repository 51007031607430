import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, Grid } from "antd";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { REACT_APP_DOMAIN_KEY } from "../../../Services/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  ApprovalRecquestIcon,
  ApprovalRecquestIconLight,
  DashboardIcon,
  DashboardIconLight,
  IdentiFicationIcon,
  IdentiFicationIconLight,
  ReferrralIcon,
  ReferrralIconLight,
  RefferalIcon,
  RefferalIconLight,
  RewardManagementIcon,
  RewardManagementIconLight,
  SecurityIconLight,
  SettingIcon,
  SettingIconLight,
} from "../../Assets/Images/SidebarIcons";
import { CardDetail } from "../../Assets/Images/SidebarIcons.jsx";
import {
  CardDetailDark,
  CardControl,
  CardControllight,
} from "../../Assets/Images/SidebarIcons.jsx";
import "./DashboardLayout.scss";
import useWindowDimensions from "../../Ui/useWindowDimensions/useWindowDimensions.jsx";
import { getData, saveData } from "../../../Services/Utils";
import * as Constants from "../../../constants/constants";
import { ApartmentOutlined, AuditOutlined } from "@ant-design/icons";
import ContactUs from "../../Pages/ContactUs/ContactUs.jsx";
import { checkPermissions } from "../../../redux/_actions";

function getItem(label, key, icon, children) {
  return { key, icon, children, label };
}

function DashboardLayout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  const width = useWindowDimensions();
  const themes = getData(Constants.APP_THEME);
  let key = getData(Constants.DEFAULT_KEY) || "1";
  const [theme, setTheme] = useState();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (themes) {
      setTheme(themes);
    }

    getMenuList();
  }, [theme]);

  /************************************** changeTheme ****************************************************/
  const changeTheme = (data) => {
    setTheme(data);
  };
  /************************************** onPressItem ****************************************************/
  const onPressItem = (key1) => {
    key = key1;
    checkAuthorization();
    saveData(Constants.DEFAULT_KEY, key);
  };
  const checkAuthorization = () => {
    setTimeout(() => {
      dispatch(checkPermissions({})).then(res => {
        console.log("checkAuthorization", res)
      }).catch(err => {
      })
    }, 100);
  }
  const getMenuList = () => {
    let items = [];
    let role_name = login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Franchise";
    let routeArray = [];
    let tab_data = login?.admin_user_access_data?.access;
    tab_data = JSON.parse(tab_data);
    let routeKey = [];
    tab_data.map((item, index) => {
      switch (item.name) {
        case "Dashboard":
          routeArray.push("/dashboard");
          routeKey.push("1")
          items.push(getItem(
            <Link to="/dashboard" onClick={() => onPressItem("1")}>
              <span className="fontFamilyText">Dashboard</span>
            </Link>,
            "1",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <DashboardIconLight /> : <DashboardIcon />}
            </span>
          ))
          break;
        case "User Details":
          routeArray.push("/userDetails");
          routeKey.push("2")
          items.push(getItem(
            <Link
              to="/userDetails"
              onClick={() => {
                onPressItem("2");
              }}
            >
              <span className="fontFamilyText ">User Details</span>
            </Link>,
            "2",
            <span className="anticon anticon-desktop ant-menu-item-icon ">
              {theme == "darkTheme" ? (
                <SecurityIconLight fillColorCustom="fillBlack" />
              ) : (
                <SecurityIconLight fillColorCustom="fillWhite" />
              )}
            </span>
          ))
          break;
        case "App Customization":
          routeArray.push("/appCustomisation");
          routeKey.push("3")
          items.push(getItem(
            <Link
              to="/appCustomisation"
              onClick={() => {
                onPressItem("3");
              }}
            >
              <span className="fontFamilyText">App Customization</span>
            </Link>,
            "3",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <IdentiFicationIconLight />
              ) : (
                <IdentiFicationIcon />
              )}
            </span>
          ))
          break;
        case "Announcements":
          routeArray.push("/annoucements");
          routeKey.push("4")
          items.push(getItem(
            <Link
              to="/annoucements"
              onClick={() => {
                onPressItem("4");
              }}
            >
              <span className="fontFamilyText">Announcements</span>
            </Link>,
            "4",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <RefferalIconLight /> : <RefferalIcon />}
            </span>
          ))
          break;
        case "Card Details":
          routeArray.push("/card-details");
          routeKey.push("5")
          items.push(getItem(
            <Link
              to="/card-details"
              onClick={() => {
                onPressItem("5");
              }}
            >
              <span className="fontFamilyText detailCardicon">Card Details</span>
            </Link>,
            "5",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <CardControllight /> : <CardControl />}
            </span>
          ))
          break;
        case "Card Control":
          routeArray.push("/cardcontrol");
          routeKey.push("6")
          items.push(getItem(
            <Link
              to="/cardcontrol"
              onClick={() => {
                onPressItem("6");
              }}
            >
              <span className="fontFamilyText">Card Control</span>
            </Link>,
            "6",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <CardDetail /> : <CardDetailDark />}
            </span>
          ))
          break;
        case "Physical card Issuance":
          routeArray.push("/card-issuance");
          routeKey.push("7")
          items.push(getItem(<Link to="/card-issuance" onClick={() => { onPressItem('7') }}><span className="fontFamilyText"> Physical card Issuance</span></Link>, "7", <span className="anticon anticon-desktop ant-menu-item-icon">{theme == "darkTheme" ? <RefferalIconLight /> : <RefferalIcon />}</span>))
          break;
        case "Referrals":
          routeArray.push("/referrals");
          routeKey.push("8")
          items.push(getItem(
            <Link
              to="/referrals"
              onClick={() => {
                onPressItem("8");
              }}
            >
              Referrals
            </Link>,
            "8",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <ReferrralIconLight /> : <ReferrralIcon />}
            </span>
          ))
          break;
        case "Approval Request":
          routeArray.push("/ApprovalRecquest");
          routeKey.push("9")
          items.push(getItem(
            <Link
              to="/ApprovalRecquest "
              onClick={() => {
                onPressItem("9");
              }}
            >
              Approval Request
            </Link>,
            "9",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <ApprovalRecquestIconLight /> : <ApprovalRecquestIcon />}
            </span>
          ))
          break;
        case "Reward Management":
          routeArray.push("/RewardManagement");
          routeKey.push("10")
          items.push(getItem(
            <Link
              to="/RewardManagement"
              onClick={() => {
                onPressItem("10");
              }}
            >
              Reward Management
            </Link>,
            "10",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <RewardManagementIconLight /> : <RewardManagementIcon />}
            </span>
          ))
          break;
        case "Issue Management":
          routeArray.push("/ContactUs");
          routeKey.push("11")
          items.push(getItem(
            <Link to="/ContactUs" onClick={() => onPressItem("11")}>
              <span className="fontFamilyText">Issue Management</span>
            </Link>,
            "11",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <AuditOutlined style={{ fontSize: "22px", color: "#fff" }} /> : <AuditOutlined style={{ fontSize: "22px", color: "#000" }} />}
            </span>
          ))
          break;
        case "Sub Admin":
          routeArray.push("/sub-admin");
          routeKey.push("12")
          items.push(getItem(
            <Link to="/sub-admin" onClick={() => onPressItem("12")}>
              <span className="fontFamilyText">Sub Admin</span>
            </Link>,
            "12",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <ApartmentOutlined style={{ fontSize: "22px", color: "#fff" }} /> : <ApartmentOutlined style={{ fontSize: "22px", color: "#000" }} />}
            </span>
          ))
          break;
      }
      return items;
    });
    routeArray.push("/adminControls");
    routeKey.push("13")
    items.push(getItem(
      <Link
        to="/adminControls"
        onClick={() => {
          onPressItem("13");
        }}
      >
        Admin Controls
      </Link>,
      "13",
      <span className="anticon anticon-desktop ant-menu-item-icon">
        {theme == "darkTheme" ? <SettingIconLight /> : <SettingIcon />}
      </span>
    ))
    // navigate(routeArray[0])
    // saveData(Constants.DEFAULT_KEY, routeKey[0]);
    setItems(items);
  }
  return (
    <>
      <Header changeTheme={changeTheme} />
      <Layout className={width < 991 ? "dashboardSidebarOuter" : ""}>
        <Sider

          width="250px"
          collapsedWidth="100px"
          onCollapse={(value) => setCollapsed(value)}
          className={`overFlow ${width < 991 ? "dashboardSidebar" : ""}`}
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={[key]}
            mode="inline"
            items={items}
          />
        </Sider>
        <Content>
          <Outlet context={theme} />
        </Content>
      </Layout>
      <Footer />
    </>
  );
}

export default DashboardLayout;
